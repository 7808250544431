

























































































































import VideoEmbedded from "@/components/VideoEmbedded.vue";
import { mapState } from "vuex";
import Vue from "vue";

export default Vue.extend({
  name: "Gallery",
  data: () => ({
    q: ""
  }),
  components: { VideoEmbedded },
  computed: {
    ...mapState(["gallery"]),
    cols() {
      const { lg, sm } = this.$vuetify.breakpoint;
      return lg ? [3, 9] : sm ? [9, 3] : [6, 6];
    }
  },
  mounted() {
    this.$store.dispatch("loadGallery");
    if (this.$route.hash) {
      //setTimeout(() => this.scrollTo(this.$route.hash), 1000);
      this.q = this.$route.hash.split("-")[1];
    }
  },
  methods: {
    galleryBack: function() {
      this.q = "";
    },
    galleryOpen: function(id: string) {
      // console.log("id:" + id);
      this.q = id;
    }
    // scrollTo: function(hashtag) {
    //   setTimeout(() => {
    //     this.$vuetify.goTo(hashtag, {
    //       duration: 300,
    //       offset: 0,
    //       easing: "easeInOutCubic"
    //     });
    //   }, 1);
    // }
  }
});
