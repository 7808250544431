


























import Vue from "vue";

export default Vue.extend({
  data: () => ({
    autoplay: false,
    playState: false
  }),
  props: {
    maxWidth: String,
    maxHeight: String,
    videoSrc: String,
    videoID: String
  },
  methods: {
    playPause: function() {
      const myVideo: HTMLMediaElement = document.getElementById(this.videoID) as HTMLMediaElement;
      if (myVideo.paused) {
        myVideo.play();
        this.playState = true;
      } else {
        myVideo.pause();
        this.playState = false;
      }
    }
  }
});
